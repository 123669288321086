<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import Pagination from "@/components/Pagination.vue";
import * as getReports from "../../api/reports/get";
import SecondaryButton from "@/components/SecondaryButton.vue";

export default {
	components: {
		Pagination,
		SecondaryButton,
	},
	data() {
		return {
			listed: false,
			users: [],
			fields: {
				id: { name: "id" },
				name: { name: "Nombre" },
				work_position: { name: "Función" },
				territory: { name: "Territorio" },
				region: { name: "Region" },
				agency: { name: "PDV" },
				received_votes: { name: "Reconocimientos recibidos" },
				emitted_votes: { name: "Reconocimientos enviados" },
			},
			filters: {
				territory_id: {
					name: "Territorio",
					options: {},
					selected: null,
				},
				region_id: {
					name: "Región",
					options: {},
					selected: null,
				},
				agency_id: {
					name: "Punto de venta",
					options: {},
					selected: null,
				},
			},
			sort: {
				by: "received_votes",
				sort: "desc",
			},
			result: null,
			exporting: false,
			exported: false,
		};
	},
	created() {
		this.loadFilter();
	},
	computed: {
		...mapGetters({
			isLoading: "loading/isLoading",
			token: "loginStore/readToken",
			//READ_USERS
			readUsers: "getUsers/readUsers",

			//READ_FILTERS
			readTerritories: "getTerritories/readTerritories",
			readRegions: "regionsStore/readRegions",
			readAgencies: "agenciesStore/readAgencies",

			//READ_ERRORS
			getUsersError: "getUsers/readError",
			deleteUserError: "deleteUser/readError",
			territoriesError: "getTerritories/readError",
			regionsError: "regionsStore/readError",
			agenciesError: "agenciesStore/readError",
		}),
	},
	methods: {
		...mapMutations({
			setLoading: "loading/SET_LOADING",
			//SET_USER_ID
			setUserId: "getUsers/SET_ID",
			setDeleteId: "deleteUser/SET_ID",

			//SET_FILTERS_ID
			setRegionId: "regionsStore/SET_ID",
			setRegionFilter: "regionsStore/SET_FILTER",
			setAgencyId: "agenciesStore/SET_ID",
			setAgencyFilter: "agenciesStore/SET_FILTER",
			setUsersFilter: "getUsers/SET_FILTER",
			setUsersPagination: "getUsers/SET_PAGINATION",

			//SET_ERRORS
			setUsersError: "getUsers/SET_ERROR",
			setDeleteUserError: "deleteUser/SET_ERROR",
			setTerritoriesError: "getTerritories/SET_ERROR",
			setRegionsError: "regionsStore/SET_ERROR",
			setAgenciesError: "agenciesStore/SET_ERROR",
		}),
		...mapActions({
			//USERS
			getUsers: "getUsers/getUsers",
			deleteUser: "deleteUser/deleteUser",

			//GET_BY_FILTERS
			getTerritories: "getTerritories/getTerritories",
			regionActions: "regionsStore/regionActions",
			agencyActions: "agenciesStore/agencyActions",
		}),
		getImage(image) {
			return `${require(`@/assets/images/views${this.$route.path.toUpperCase()}/${image}`)}`;
		},
		exportReport() {
			if (!this.exported) {
				this.exporting = true;
				this.exported = false;
				let token = this.token;

				let params = {};

				for (const property in this.filters) {
					params[property] = this.filters[property].selected;
				}

				getReports
					.exportVotesByUsers(token, params)
					.then((result) => {
						this.$swal(result.message);
					})
					.finally(() => {
						this.listed = true;
						this.setLoading(false);
						this.exporting = false;
						this.exported = true;
					});
			}
		},
		loadReport(page = 1) {
			this.clearErrors();
			this.setLoading(true);
			// this.setUserId(null);
			// this.setUsersPagination(null);
			//

			let token = this.token;

			let params = {
				page: page,
				size: 15,
				sort_by: this.sort.by,
				sort: this.sort.sort,
			};

			for (const property in this.filters) {
				params[property] = this.filters[property].selected;
			}

			getReports
				.getVotesByUsers(token, params)
				.then((result) => {
					this.$set(this, "result", result);
				})
				.finally(() => {
					this.listed = true;
					this.setLoading(false);
				});
		},
		loadFilter() {
			this.listed = false;
			this.setLoading(true);
			this.clearErrors();
			this.clearFilter("territory_id");
			this.clearFilter("region_id");
			this.clearFilter("agency_id");
			this.getTerritories()
				.then((response) => {
					if (response) {
						if (this.readTerritories.length > 0) {
							for (let t in this.readTerritories) {
								this.filters.territory_id.options[
									this.readTerritories[t].id
								] = this.readTerritories[t].name;
							}
						}
					}
				})
				.finally(() => {
					this.setLoading(false);
					this.loadReport();
				});
		},
		refreshFilter(selected) {
			this.setLoading(true);
			this.clearErrors();
			this.listed = false;
			this.users = [];

			this.exporting = false;
			this.exported = false;

			if (selected == "territory_id") {
				this.clearFilter("region_id");
				this.clearFilter("agency_id");

				//REGIONS
				this.setRegionId(null);
				this.setRegionFilter(this.filters.territory_id.selected);
				this.regionActions("getRegions")
					.then((response) => {
						if (response) {
							if (this.readRegions.length > 0) {
								for (let r in this.readRegions) {
									this.filters.region_id.options[
										this.readRegions[r].id
									] = this.readRegions[r].name;
								}
							}
						}
					})
					.finally(() => {
						this.setLoading(false);
						this.loadReport();
					});
			} else if (selected == "region_id") {
				this.clearFilter("agency_id");

				//AGENCIES
				this.setAgencyId(null);
				this.setAgencyFilter(this.filters.region_id.selected);
				this.agencyActions("getAgencies")
					.then((response) => {
						if (response) {
							if (this.readAgencies.length > 0) {
								for (let a in this.readAgencies) {
									this.filters.agency_id.options[
										this.readAgencies[a].id
									] = this.readAgencies[a].name;
								}
							}
						}
					})
					.finally(() => {
						this.setLoading(false);
						this.loadReport();
					});
			} else {
				this.setLoading(false);
				this.loadReport();
			}
		},
		clearFilter(filter) {
			this.filters[filter].selected = null;
			this.filters[filter].options = {};
		},
		clearErrors() {
			this.setUsersError(null);
			this.setDeleteUserError(null);
			this.setTerritoriesError(null);
			this.setRegionsError(null);
			this.setAgenciesError(null);
		},
		changePage(page) {
			this.loadReport(page);
		},
		sortSet(field) {
			if (this.sort.by == field) {
				this.sort.sort = this.sort.sort == "desc" ? "asc" : "desc";
			}

			this.sort.by = field;

			this.loadReport();
		},
	},
};
</script>

<template>
	<div class="main">
		<form class="bg-gray p-6 pb-10 mb-3 shadow-lg">
			<div
				class="flex justify-evenly items-center md:justify-between flex-wrap lg:flex-nowrap"
			>
				<label
					:for="key"
					v-for="(f, key) in filters"
					:key="key"
					class="w-full md:w-1/4 lg:w-full flex flex-col justify-between md:h-20 mt-3 md:mt-0 md:mr-2 lg:m-0 lg:mr-3 text-lg flex-auto"
				>
					<span class="w-full">{{ f.name }}</span>
					<select
						@change="refreshFilter(key)"
						class="w-full h-12 bg-white rounded-md px-4 py-2"
						v-model="f.selected"
						name="filter"
						:id="key"
					>
						<option :value="null" selected>Todas</option>
						<option v-for="(v, i) in f.options" :key="i" :value="i">
							{{ v }}
						</option>
					</select>
				</label>
				<div
					class="flex-none md:h-20 md:w-full lg:w-auto flex flex-col md:flex-row justify-center items-end mt-6 md:mt-0"
				>
					<SecondaryButton
						@click.native="loadReport(1)"
						text="Buscar"
						class="mb-3 md:mb-0 md:mr-2"
					/>
					<SecondaryButton
						@click.native="exportReport"
						:text="`${
							exporting
								? 'Exportando'
								: exported
								? 'Enviado'
								: 'Exportar'
						}`"
						button-color="bg-green"
						:class="{
							'disabled opacity-50': exporting || exported,
						}"
					/>
				</div>
			</div>
			<div class="w-full">
				<span
					v-if="getUsersError"
					class="block my-5 red text-center b"
					>{{ getUsersError }}</span
				>
				<span
					v-if="deleteUserError"
					class="block my-5 red text-center b"
					>{{ deleteUserError }}</span
				>
				<span
					v-if="territoriesError"
					class="block my-5 red text-center b"
					>{{ territoriesError }}</span
				>
				<span
					v-if="regionsError"
					class="block my-5 red text-center b"
					>{{ regionsError }}</span
				>
				<span
					v-if="agenciesError"
					class="block my-5 red text-center b"
					>{{ agenciesError }}</span
				>
			</div>
		</form>
		<div v-if="!isLoading && result">
			<div v-if="result.items.length > 0" id="no-more-tables">
				<table class="block lg:table table-auto w-full">
					<thead class="block lg:table-header-group elektra-bg-red">
						<tr class="block lg:table-row border border-gray">
							<th
								class="block text-white lg:table-cell b border-r border-gray p-3 cursor-pointer"
								:class="{ 'opacity-60': key != sort.by }"
								v-for="(field, key) in fields"
								:key="key"
								@click="sortSet(key)"
								v-show="key != 'id'"
							>
								<div
									class="flex justify-center items-center relative"
								>
									<span class="text-center">
										{{ field.name }}
									</span>
									<font-awesome-icon
										v-if="sort.by != key"
										class="absolute right-0 bottom-1/2 transform translate-y-1/2 text-md opacity-50"
										icon="fa-solid fa-sort"
									/>
									<font-awesome-icon
										v-else-if="sort.sort != 'asc'"
										class="absolute right-0 bottom-1/2 transform translate-y-1/2 text-md"
										icon="fa-solid fa-sort-down"
									/>
									<font-awesome-icon
										v-else
										class="absolute right-0 bottom-1/2 transform translate-y-1/2 text-md"
										icon="fa-solid fa-sort-up"
									/>
								</div>
							</th>
							<!-- 					<th
						class="block lg:table-cell b text-white border-r py-3 px-1"
					>Acciones</th> -->
						</tr>
					</thead>
					<tbody class="block md:flex flex-wrap lg:table-row-group">
						<tr
							class="block odd:bg-gray-light hover:bg-orange lg:table-row border-t border-b border-gray box-border"
							:class="
								result.items.length > 1
									? 'w-full md:w-1/2 lg:w-full'
									: 'w-full'
							"
							v-for="(user, i) in result.items"
							:key="i"
						>
							<td
								class="block before:text-center before:justify-center first:border-l lg:table-cell text-center border-r border-gray-light py-3 px-1"
								:class="{ 'green b': key == 'id' }"
								v-for="(field, key) in fields"
								:key="key"
								v-show="key != 'id'"
							>
								<span
									v-if="key == 'name'"
									v-capitalizeWords="
										user[key]
											? user[key]
											: `${user.first_name} ${user.last_name}`
									"
								>
								</span>
								<span
									v-else-if="
										key != 'received_votes' &&
										key != 'emitted_votes' &&
										key != 'id'
									"
									v-capitalizeWords="user[key]"
								>
								</span>
								<span v-else>
									{{ user[key] }}
								</span>
							</td>
						</tr>
					</tbody>
				</table>
				<div class="flex my-3 pb-3">
					<Pagination
						class="mx-auto"
						:total-pages="result.pages"
						:total="result.total"
						:per-page="result.size"
						:current-page="result.page"
						@pagechanged="changePage"
					/>
				</div>
			</div>
			<span v-else-if="listed" class="block my-3 blue text-center b"
				>No se encontraron reconocimientos</span
			>
		</div>
		<span v-else-if="listed" class="block my-3 blue text-center b"
			>No se encontraron reconocimientos</span
		>
	</div>
</template>

<style lang="scss" scoped>
.disabled {
	&:hover {
		animation: none;
	}
	cursor: default;
}
/*
	Label the data
	*/

@media (max-width: 1023px) {
	$fields: 1 "id", 2 "Nombre", 3 "Función", 4 "Territorio", 5 "Región",
		6 "PDV", 7 "Recibidos", 8 "Enviados";

	@each $i, $field in $fields {
		#no-more-tables td:nth-child(#{$i}):before {
			content: $field;
		}
	}
}
</style>
